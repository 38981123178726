import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import WorkTeaser from 'components/workTeaser'
import MEDIA from 'helpers/mediaTemplates'

const WorksListWrapper = styled.div`
  ${MEDIA.MIN_TABLET`
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
  column-gap: 2rem;
  `};
`

const usePosts = () => {
  const { posts } = useStaticQuery(
    graphql`
      query works {
        posts: allMdx(filter: { fileAbsolutePath: { regex: "/work/" } }) {
          edges {
            node {
              frontmatter {
                title
                description
                thumbnailImage {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  return posts
}

const WorksListFull = () => {
  const posts = usePosts()

  return (
    <WorksListWrapper>
      {posts.edges.map(({ node }, index) => {
        return (
          <WorkTeaser
            key={index}
            title={node.frontmatter.title}
            description={node.frontmatter.description}
            url={node.fields.slug}
            image={node.frontmatter.thumbnailImage}
          />
        )
      })}
    </WorksListWrapper>
  )
}

export default WorksListFull
