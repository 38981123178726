import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { fontSizeLead, headingsColor } from 'constants/typography'
import colors from 'constants/colors'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  a {
    text-decoration: none;
    display: block;
    margin-bottom: 2rem;
  }
`

const Title = styled.h2`
  font-size: ${fontSizeLead};
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`

const WorkTeaser = ({ title, description, image, url }) => (
  <Wrapper>
    <Link to={url}>
      {image && <Img fluid={image.childImageSharp.fluid} />}
      <Title>{title}</Title>
      <p>{description}</p>
    </Link>
  </Wrapper>
)

export default WorkTeaser

WorkTeaser.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.any,
  url: PropTypes.string,
}
